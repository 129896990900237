import React from 'react'
import { Helmet } from 'react-helmet'

type HelmetProps = {
  title: string
  description: string
}

export const HelmetComponent = ({ title, description }: HelmetProps) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="facebook-domain-verification" content="b5ec0s587mto7ea3142vhogc6tsxje" />
  </Helmet>
)
