import React from 'react'
import Button from '../components/Button'
import { HelmetComponent } from '../components/HelmetComponent'
import Layout from '../components/Layout'
import {
  BenefitText,
  ButtonBenefitContainer,
  ButtonContainer,
  Container,
  Description,
  IconContainer,
  ImageContainer,
  InfoBenefitContainer,
  PrimaryTitle,
  SecondaryTitle,
  SectionImage,
  StyledBenefitBar,
  StyledBold,
  StyledIcon,
  StyledRow,
  StyledRowBenefitBar,
  StyledSection,
} from '../components/StylesPages/benefitsStyle'
import AuthContext from '../context/auth-context'

const DiscountImage = require('../assets/images/pages/benefits/discount.jpg')
const GiftImage = require('../assets/images/pages/benefits/gift.jpg')
const MenuImage = require('../assets/images/pages/benefits/menu.jpg')
const DrinkImage = require('../assets/images/pages/benefits/drink.jpg')
const BellImage = require('../assets/images/pages/benefits/bell.jpg')

const PercentImg = require('../assets/images/pages/icons/percent-b.svg')
const BellImg = require('../assets/images/pages/icons/bell-b.svg')
const DrinkImg = require('../assets/images/pages/icons/drink.svg')
const GiftImg = require('../assets/images/pages/icons/gift.svg')
const MenuImg = require('../assets/images/pages/icons/menu-b.svg')

export default () => (
  <Layout themeType="dark" currentPage={'benefits'}>
    <HelmetComponent 
      title="Beneficios - Grande Table"
      description="Disfruta de beneficios exclusivos, reservas y descuentos en los mejores restaurantes, eventos gastronómicos, delivery gratuito en tus restaurantes favoritos, y experiencias culinarias únicas."
    />
    <Container className="container-fluid">
      <StyledRow className="row">
        <StyledBenefitBar className="container">
          <StyledRowBenefitBar className="row">
            <div className="col-md-6">
              <BenefitText>Beneficios</BenefitText>
            </div>
            <AuthContext.Consumer>
              {(context: any) => {
                return context.user ? (
                  ''
                ) : (
                  <ButtonBenefitContainer className="col-md-6">
                    <Button
                      link="membership"
                      text="Quiero ser parte del club"
                      id="CLICKBENEFICIOS_SERPARTEDECLUB1"
                    />
                  </ButtonBenefitContainer>
                )
              }}
            </AuthContext.Consumer>
          </StyledRowBenefitBar>
        </StyledBenefitBar>
      </StyledRow>
      <StyledSection color="white" className="row padding-top-bottom">
        <div className="container">
          <div className="row">
            <ImageContainer>
              <SectionImage src={DiscountImage} />
            </ImageContainer>
            <InfoBenefitContainer className="col-md-6">
              <IconContainer>
                <StyledIcon src={PercentImg} />
              </IconContainer>
              <SecondaryTitle contrast={false}>Descuentos</SecondaryTitle>
              <PrimaryTitle>Exclusivos</PrimaryTitle>
              <Description>
                Todos los días podrás disfrutar nuevos aromas y sabores con descuentos desde el 10%
                hasta el 25% en toda tu cuenta, en todos los restaurantes afiliados al club gourmet.
              </Description>
            </InfoBenefitContainer>
          </div>
        </div>
      </StyledSection>
      <StyledSection color="#c59d5f" className="row padding-top-bottom">
        <div className="container">
          <div className="row">
            <InfoBenefitContainer className="col-md-6" contrast={true}>
              <IconContainer>
                <StyledIcon src={DrinkImg} />
              </IconContainer>
              <SecondaryTitle contrast={true}>Eventos</SecondaryTitle>
              <PrimaryTitle>Especiales</PrimaryTitle>
              <Description>
                Tendrás acceso exclusivo a los eventos gastronómicos más relevantes de la ciudad,
                disfruta la mezcla de sabores mágicos que caracterizan los restaurantes afiliados.
              </Description>
            </InfoBenefitContainer>
            <ImageContainer>
              <SectionImage src={DrinkImage} />
            </ImageContainer>
          </div>
        </div>
      </StyledSection>
      <StyledSection color="white" className="row padding-top-bottom">
        <div className="container">
          <div className="row padding-top-bottom">
            <ImageContainer>
              <SectionImage src={BellImage} />
            </ImageContainer>
            <InfoBenefitContainer className="col-md-6">
              <IconContainer>
                <StyledIcon src={BellImg} />
              </IconContainer>
              <SecondaryTitle contrast={false}>Preferencia</SecondaryTitle>
              <PrimaryTitle>en Reservaciones</PrimaryTitle>
              <Description>
                Escoge el restaurante que desees visitar y tan solo mencionando que eres miembro del
                club Grande Table, tienes preferencia al momento de hacer reservación de mesas y
                trato privilegiado para ti y tus amigos.
              </Description>
            </InfoBenefitContainer>
          </div>
        </div>
      </StyledSection>
      <StyledSection color="white" className="row padding-top-bottom">
        <div className="container">
          <div className="row">
            <InfoBenefitContainer className="col-md-6">
              <IconContainer>
                <StyledIcon src={MenuImg} />
              </IconContainer>
              <SecondaryTitle contrast={false}>Experiencias</SecondaryTitle>
              <PrimaryTitle>Personalizadas</PrimaryTitle>
              <Description>
                Tienes acceso especial a descubrir los nuevos sabores propuestos por los chefs en
                sus innovaciones de menús antes de que cualquier persona los pueda probar, degustar
                los nuevos vinos antes que sean parte del menú, asistir a catas de vino{' '}
                <StyledBold>
                  (Asiste a catas de vino y da el visto bueno para que puedan estar dentro de tu
                  menú favorito)
                </StyledBold>{' '}
                y vivir una experiencia gastronómica inigualable a 4 manos cocinando junto a tu chef
                favorito.
              </Description>
            </InfoBenefitContainer>
            <ImageContainer>
              <SectionImage src={MenuImage} />
            </ImageContainer>
          </div>
        </div>
      </StyledSection>
      <AuthContext.Consumer>
        {(context: any) => {
          return context.user ? (
            ''
          ) : (
            <StyledRow className="row">
              <ButtonContainer className="col-md-12">
                <Button
                  link="membership"
                  text="Quiero ser parte del club"
                  id="CLICKBENEFICIOS_SERPARTEDECLUB2"
                />
              </ButtonContainer>
            </StyledRow>
          )
        }}
      </AuthContext.Consumer>
    </Container>
  </Layout>
)
