import styled from 'styled-components'
import media from '../../styles/media'

interface SectionProps {
  color: string
}

interface InfoProps {
  contrast?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const StyledRow = styled.div``

export const BenefitText = styled.h1`
  margin-top: 1rem;
  color: #c59d5f;
  font-weight: normal;
  font-family: 'DroidSerif';
  ${media.md`  
    margin-top: 0;
  `};
`

export const StyledBenefitBar = styled.div`
  padding-top: 0;
  ${media.md`  
    padding-top: 2rem;
  `};
`
export const StyledRowBenefitBar = styled.div`
  align-items: center;
`

export const ButtonBenefitContainer = styled.div`
  display: none;
  ${media.md`  
display: block;
text-align: right;

  `};
`

export const StyledSection = styled.div`
  background: ${(props: SectionProps) => props.color};
  &.padding-top-bottom {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;
`
export const SectionImage = styled.img`
  max-width: 100%;
  margin: auto;
  order: 1;
  ${media.sm`  
  order:unset;
  `};
`
export const InfoBenefitContainer = styled.div<InfoProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  color: ${(props: InfoProps) => (props.contrast === true ? 'white' : '#000')};
  order: 2;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  ${media.sm`  
    text-align: left;
    order:unset;
  `};
`

export const IconContainer = styled.div``
export const StyledIcon = styled.img`
  max-width: 50px;
  height: 50px;
`
export const SecondaryTitle = styled.h4`
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: 1.2px;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 5px;
  color: inherit;
  font-size: 1.1rem;
  color: ${(props: InfoProps) => (props.contrast === true ? '#e8e8e8' : '#666666')};
`
export const PrimaryTitle = styled.h2`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.67;
  letter-spacing: 2.3px;
  text-transform: uppercase;
  font-size: 2.1rem;
`
export const Description = styled.p`
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 1.2px;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const ButtonContainer = styled.div`
  padding-top: 2rem;
  text-align: center;
  padding-bottom: 2rem;
`

export const StyledBold = styled.span`
  font-weight: 700;
`
