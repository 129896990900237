import React from 'react'
import { navigate } from 'gatsby'
import { CSSProperties } from 'styled-components'
import { StyledButton, StyledAccountButton } from './style'

interface Props {
  text: string
  link: string
  id?: string
  type?: string
  callback?: Function
  className?: string
  style?: CSSProperties
}

const handleClick = (link: string, callback: any = null) => {
  if (callback) {
    callback()
    return
  }
  navigate(`/${link}`)
}

const Button = ({ text, link, id, type, callback, className, style }: Props) => (
  <StyledButton
    onClick={() => handleClick(link, callback)}
    id={id}
    type={type ? type : 'primary'}
    link={link}
    className={className}
    style={style}
  >
    {text}
  </StyledButton>
)

const AccountButton = (props: any) => (
  <StyledAccountButton {...props}>{props.children}</StyledAccountButton>
)

export default Button
export { AccountButton }
